// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

//Bootstrap@5.0.0
import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/js/bootstrap.min"

import "/node_modules/bootstrap/scss/bootstrap.scss"

// custom CSS styles
import "./src/styles/global.scss"

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    `/careers`,
    `/contact`,
    `/buy-a-home`,
    `/refinance-a-home`,
  ]

  // if the new route is part of the list above, prevent scroll to top.
  if (scrollToTopRoutes.includes(pathname)) {
    return false
  }
  return true
}
